import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { FormDateComponent } from '../form/controls/date';
import { FormArrayValue } from '../form.utils';
import { MembershipAssociateForm } from './membership-associate-form.model';
import { MembershipAssociateVm } from './membership-associate.vm';
import { nameSuffix } from '../../../constants/name-suffix';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { getMembershipAssociateFormErrors } from '../../../store/form-messages';
import { FormCheckBoxMedicalComponent } from '../form/controls/checkbox-medical';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ButtonLinkComponent } from '../ava-link/ava-link.component';

@Component({
  selector: 'ava-membership-associate-form',
  template: `
    @if (errorMessages$ | async; as errorMessages) {
      <div class="ava-column ava-gap-2 ava-membership-associates">
        @for (associateGroup of formArray.controls; track associateGroup; let i = $index) {
          <div [attr.id]="'membership-associate-' + i" [formGroup]="associateGroup" class="ava-column ava-gap-2">
            <div class="ava-row ava-space-between ava-align-center">
              <div class="ava-form-label">Associate {{ totalAssociates + 1 + i }}</div>

              @if (!associateGroup.controls.membershipNumber?.value) {
                <ava-link (click)="removeAssociates(i)" (keydown.enter)="removeAssociates(i)">
                  <span>
                    <i [style.font-size]="'14px'" nz-icon nzTheme="outline" nzType="close"></i>
                    Remove
                  </span>
                </ava-link>
              }
            </div>
            <ava-form-input
              [errorMessages]="errorMessages.firstName"
              placeholder="First Name"
              formControlName="firstName"
            />
            <div class="ava-row ava-gap-1">
              <ava-form-input
                [errorMessages]="errorMessages.lastName"
                class="ava-flex"
                placeholder="Last Name"
                formControlName="lastName"
              />
              <ava-form-select
                placeholder="Suffix"
                [options]="nameSuffix"
                formControlName="suffix"
                class="ava-membership-associates__suffix"
              />
            </div>
            <div class="ava-row ava-gap-2 membership-associate__email">
              <ava-form-date
                [errorMessages]="errorMessages.birthday"
                placeholder="Date of Birth (mm/dd/yyyy)"
                formControlName="birthday"
                class="ava-flex"
              />
              <ava-form-input
                [errorMessages]="errorMessages.email"
                placeholder="Email"
                formControlName="email"
                class="ava-flex"
              />
            </div>

            @if (showAccidentMedicalPlan) {
              <div class="ava-row">
                <ava-form-checkbox-medical
                  formControlName="accidentMedicalPlan"
                  [accidentMedicalPlanPrice]="accidentMedicalPlanPrice"
                  (changed)="accidentMedicalChanged.emit($event)"
                />
              </div>
            }
          </div>
        }

        <div
          tabindex="0"
          (click)="addAssociates()"
          (keydown.enter)="addAssociates()"
          [ngClass]="{
            'ava-membership-associates__add--disabled':
              formArray.invalid || formArray.length + totalAssociates >= maxAssociate,
          }"
          class="ava-membership-associates__add {{
            formArray.invalid || formArray.length + totalAssociates >= maxAssociate
              ? ''
              : 'focus-visible:outline-blue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
          }}"
        >
          <i [style.font-size]="'36px'" nz-icon nzTheme="twotone" [hidden]="false" nzType="plus-circle"></i>
          <p>
            Add Additional Member(s)
            <br />
            *Must live in the same household.
          </p>
        </div>
      </div>
    }
  `,
  styles: [
    `
      @import '../../../styles/ava-responsive';

      .ava-membership-associates {
        &__suffix {
          max-width: 100px;
          width: 100%;
        }

        .membership-associate {
          &__email {
            @include screenTabletLargeDown() {
              flex-direction: column;
            }
          }
        }

        &__add {
          display: flex;
          gap: 1rem;
          margin: 1rem 0;
          cursor: pointer;

          &--disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          p {
            font-size: 16px;
            line-height: 1.235;
            word-wrap: break-word;
            font-weight: 200;
          }
        }
      }
    `,
  ],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    FormDateComponent,
    CommonModule,
    NzToolTipModule,
    FormCheckBoxMedicalComponent,
    NzButtonModule,
    ButtonLinkComponent,
  ],
  standalone: true,
})
export class MembershipAssociateFormComponent implements OnChanges {
  membershipAssociateVm = inject(MembershipAssociateVm);
  controlContainer = inject(ControlContainer);
  store = inject(Store<AppStore>);
  @Input() requiredBirthday = false;
  @Input() accidentMedicalPlanPrice = 0;
  @Input() showAccidentMedicalPlan: boolean | null = false;
  @Input() totalAssociates = 0;
  @Input() maxAssociate = 9;
  @Input() formValues: FormArrayValue<FormGroup<MembershipAssociateForm>> = [];
  @Output() addAssociate = new EventEmitter();
  @Output() removeAssociate = new EventEmitter<number>();
  @Output() accidentMedicalChanged = new EventEmitter();
  errorMessages$ = this.store.select(getMembershipAssociateFormErrors);
  readonly nameSuffix = nameSuffix;

  get formArray(): FormArray<FormGroup<MembershipAssociateForm>> {
    let control = this.membershipAssociateVm.create();
    if (this.controlContainer) {
      control = this.controlContainer.control as FormArray<FormGroup<MembershipAssociateForm>>;
    }

    return control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formValues) {
      this.updateAssociatesFormArray(this.formValues);
    }
  }

  updateAssociatesFormArray(values: FormArrayValue<FormGroup<MembershipAssociateForm>>) {
    const associates = values || [];
    if (this.formArray.length === 0 && associates.length > 0) {
      associates
        .map((values) => this.membershipAssociateVm.createAssociate(values))
        .forEach((associate, index) => {

          if (this.requiredBirthday) {
            associate.controls.birthday.addValidators([Validators.required]);
          }

          this.formArray.insert(index, associate);
        });
    }
  }

  addAssociates() {
    if (this.formArray.valid && this.formArray.length + this.totalAssociates < this.maxAssociate) {
      this.addAssociate.emit();
    }
  }

  removeAssociates(index: number) {
    this.removeAssociate.emit(index);
  }
}
